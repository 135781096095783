import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import "./assets/style.css";
import vClickOutside from "click-outside-vue3";

const app = createApp(App);

app.use(vClickOutside);
app.use(store);
app.use(router);
app.mount('#app');
