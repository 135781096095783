import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';
import NewsletterView from '../views/NewsletterView.vue';
import ContactView from '../views/ContactView.vue';
import ProjectsView from '../views/ProjectsView.vue';
import ProjectView from '../views/ProjectView.vue';
import BlogView from '../views/BlogView.vue';
import BlogArticleView from '../views/BlogArticleView.vue';
import DonateView from '../views/DonateView.vue';
import LoginView from '../views/LoginView.vue';
import SignUpView from '../views/SignUpView.vue';
import VolunteerView from '../views/VolunteerView.vue';
import VolunteerApplyView from '../views/VolunteerApplyView.vue';
import AppView from '../views/AppView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
  },
  {
    path: '/newsletter',
    name: 'newsletter',
    component: NewsletterView,
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsView,
  },
  {
    path: '/projects/:project',
    name: 'project',
    component: ProjectView,
  },
  {
    path: '/blog',
    name: 'blog',
    component: BlogView,
  },
  {
    path: '/blog/:article',
    name: 'blog_article',
    component: BlogArticleView,
  },
  {
    path: '/donate',
    name: 'donate',
    component: DonateView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/sign-up',
    name: 'signup',
    component: SignUpView,
  },
  {
    path: '/volunteer',
    name: 'volunteer',
    component: VolunteerView,
  },
  {
    path: '/volunteer/apply',
    name: 'volunteer_apply',
    component: VolunteerApplyView,
  },
  {
    path: '/app',
    name: 'app',
    component: AppView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
