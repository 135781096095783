<template>
	<ul class="project-list">
    <!-- List -->
    <ProjectListItem @donate="show = true"></ProjectListItem>

    <!-- Modal -->
    <Modal title="Donate" v-if="show" @close="show = false">
      <DonationForm></DonationForm>
      
      <!-- <ProjectList></ProjectList> -->
    </Modal>
  </ul>
</template>

<script setup>
  import { ref } from "vue"
  import ProjectListItem from "@/components/ProjectListItem"
  import Modal from "@/components/Modal"
  import DonationForm from "@/components/DonationForm"

  const show = ref(false)
</script>

<style scoped>
  .project-list {
  margin: auto;
  margin-top: 65px;
  list-style-type: none;
}
</style>