<template>
  <div>
    <div class="container">
      <S2 badge="About Us" title="Our team is present worldwide  working together to help <span>heal the world.</span>" text="Your donation empowers families in poverty to choose how best to improve their lives. You decide which projects and exactly with which resources you want to support.">
        <p>
          Our core principle is clear: establishing a transparent and democratic nonprofit sector in which every donation is allocated according to our donors' choices. Using our platform, we connect donors directly to the causes they're passionate about, empowering them to support projects with real, measurable results. We have faith that by collaborating, we can shape a brighter world for all.
        </p>
      </S2>
    </div>
    <S3 title="The <span>founding Members</span" :items="s3Items"></S3>
  </div>
</template>

<script setup>
  import { ref } from "vue"
  import S2 from "@/components/S2"
  import S3 from "@/components/S3"

  const s3Items = ref([
    {
      icon: "",
      title: "Jonas Attiah",
      text: "Founder & CEO<br> We are engaged in a battle against hunger and those who exploit it for their gain."
    },
    // {
    //   icon: "",
    //   title: "Lea Attiah",
    //   text: "You can see where your money goesTrack every single transaction of our organization"
    // },
    // {
    //   icon: "",
    //   title: "Frank Paladino",
    //   text: "You can see where your money goesTrack every single transaction of our organization"
    // },
    // {
    //   icon: "",
    //   title: "Yaw Attiah",
    //   text: "You can see where your money goesTrack every single transaction of our organization"
    // }
  ])
</script>