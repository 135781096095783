<template>
  <div class="footer">
      <div class="container">
        <div class="col-1">
          <div>All Nations United Organization</div>
          <br>
          <div>Prodromou 121, Nicosia 2064, Cyprus</div>
          <div>+357 91 1138 04</div>
          <div>info@anuo.org</div>
        </div>
        <div class="col-2">
          <ul class="links">
            <li><strong>Member</strong></li>
            <li><router-link to="/volunteer">Volunteer</router-link></li>
          </ul>
          <ul class="links">
            <li><strong>Links</strong></li>
            <li><router-link to="/projects">Projects</router-link></li>
            <li><router-link to="/about">About Us</router-link></li>
            <li><router-link to="/newsletter">Newsletter</router-link></li>
            <li><router-link to="/blog">Blog</router-link></li>
            <li><router-link to="/contact">Contact</router-link></li>
          </ul>
          <ul class="links">
            <li><strong>Legal</strong></li>
            <li><router-link to="/terms">Terms and Conditions</router-link></li>
            <li><router-link to="/privacy">Privacy Policy</router-link></li>
          </ul>
        </div>
      </div>
    </div>
</template>

<style scoped>
  .footer .col-2 {
    display: flex;
    gap: 32px;
    margin-top: 66px;
  }

  .links li {
    margin-bottom: 8px;
  }

  .links a {
    color: #ffffff;
  }

  .links strong {
    font-size: 15px;
    color: #05AE66;
  }

  @media (min-width: 768px) {
    .footer .container {
      display: flex;
      justify-content: space-between;
    }

    .footer .col-2 {
      margin-top: 0;
    }
  }
</style>