<template>
	<div class="container">
      <div class="nav">
        <!-- Logo -->
        <div class="logo"><router-link to="/">anuo<span>All Nations United</span></router-link></div>

        <!-- Menu -->
        <ul>
          <!-- <li><a href="#">How it works</a></li> -->
          <li><router-link to="/about">About Us</router-link></li>
          <li><router-link to="/projects">Projects</router-link></li>
          <li><router-link to="/donate">Donate</router-link></li>
          <!-- <li><a href="#">Transparency</a></li> -->
          <!-- <li><a href="#">Our Roadmap</a></li> -->
        </ul>

        <!-- Auth -->
        <div class="nav-auth">
          <router-link to="/login" class="btn btn-s btn-inv">Login</router-link>
          <router-link to="/sign-up" class="btn btn-s">Sign Up</router-link>
        </div>
      </div>
    </div>
</template>