<template>
  <div>
    <!-- Section 1 -->
    <div class="s1">
      <div class="s1-hero">
        <!-- Title -->
        <h1>Save a childs <span>life</span> today</h1>

        <!-- Subtitle -->
        <p>We focus on giving children in difficult situations a better life. Become a donor or even join our team and visit our projects.</p>

        <!-- CTA -->
        <router-link class="btn btn-l" to="/projects"></router-link>
      </div>

      <div class="img"></div>
    </div>

    <!-- Section 2 -->
    <div class="container">
      <S2 badge="How it works" title="You get to select the purpose of <span>your donation.</span>" text="See where help is needed the most, and decide what and how much you want to contribute.">
        <p>
          Our core principle is clear: establishing a transparent and democratic nonprofit sector in which every donation is allocated according to our donors' choices. Using our platform, we connect donors directly to the causes they're passionate about, empowering them to support projects with real, measurable results. We have faith that by collaborating, we can shape a brighter world for all.
        </p>

        <!-- Map -->
        <Map style="max-height: 566px;"></Map>
      </S2>
    </div>

    <!-- Section 3 -->
    <S3 title="You <span>get</span>" :items="s3Items"></S3>

    <!-- Section 4 -->
    <div class="s4">
      <!-- Title -->
      <h1>Our <span>projects</span></h1>

      <!-- Subtitle -->
      <p>Your contribution matters, and we value your trust in our mission. Rest assured that your donation will be utilized responsibly and efficiently.</p>

      <!-- List -->
      <ProjectList></ProjectList>
    </div>

    <!-- Section 5 -->
    <div class="s5">
      <div class="container">
        <div></div>
        <h1>We are politically neutral. Our investment goes directly to individuals. We do not collaborate with other organizations or governments. We are completely independent and will always remain independent.</h1>
        <div class="btn btn-black">Become a member</div>
      </div>
    </div>

    <!-- Section 6 -->
    <S6 title="Explore highlights from the <span>field</span>" text="Your contribution matters, and we value your trust in our mission. Rest assured that your donation will be utilized responsibly and efficiently." :items="s6Items"></S6>

    <!-- Section 7 -->
    <div class="s7">
      <div class="container">
        <h1>Help someone live a life as full as your own</h1>
        <div class="row">
          <div class="btn">Give now</div>
          <div class="btn btn-black">Sign up</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from "vue"
  import S2 from "@/components/S2"
  import S3 from "@/components/S3"
  import S6 from "@/components/S6"
  import ProjectList from "@/components/ProjectList"
  import Map from "@/components/Map"

  const s3Items = ref([
    {
      icon: "",
      title: "Transparency",
      text: "We provide complete visibility into the allocation of your contributions."
    },
    {
      icon: "",
      title: "Live Updates",
      text: "We provide real-time tracking for all our projects, videos, and live streams."
    },
    {
      icon: "",
      title: "Membership",
      text: "Making your first donation, and it opens up the opportunity to visit our projects."
    },
  ])

  const s6Items = [
    {
      badge: "Creative ideas",
      date: "June 20, 2023",
      title: "Fundraising 101: Essential Tips for Getting Started",
      text: "Our mission is to combat the water crisis and improve the health and well-being of communities in need.",
      btn: {
        text: "Read More",
        to: "/"
      }
    }
  ]
</script>