<template>
	<li class="project-list-item">
          <div class="c1">
            <div class="badge">Food Delivery</div>
            <h2>Save 30 Lives</h2>
            <p>$30 a month is enough to provide a hungry child in Africa with food for one month.</p>
            <div class="prog-bar"><div class="inner" style="width: 18%;"></div></div>
            <h3>Goal: $10,800</h3>
            <div class="btn" @click="$emit('donate')">Donate now</div>
          </div>

          <div class="c2">
            <img src="/img/project-1.png">
          </div>
        </li>
</template>

<style scoped>
.project-list-item {
  border-radius: 12px;
  border: 1px solid #E8EEEC;
  padding: 24px;
  display: grid;
  gap: 32px;
}

.project-list-item .c1 {
  width: 100%;
  max-width: 504px;
}

.project-list-item .badge {
  padding: 6px 15px;
  background: #F5FFF5;
  border-radius: 999px;
  color: #05AE66;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: inline-block;
}

.project-list-item h2 {
  color: #1E1E1E;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.48px;
  margin-top: 8px;
}

.project-list-item p {
  color: #667185;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
}

.project-list-item img {
  width: 100%;
  max-width: 494px;
}

.project-list-item .btn {
  margin-top: 45px;
}

.project-list-item h3 {
  color: #054228;
  font-size: 14px;
  line-height: 27px;
  margin-top: 10px;
  font-weight: 400;
}

@media (min-width: 768px) {
  .project-list-item {
    display: flex;
    justify-content: space-between;
  }
}
</style>